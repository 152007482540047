<template>
    <div :class="['pagination-page-btn', {'active' : isActive}, {'dots' : pageNumber===0}]" @click="onClick()">
        <span :class="['pagination-page-btn__number', 'subtitle']" v-html="page" />
    </div>
</template>

<script>
    export default {
        name: "PaginationBtn",
        props: {
            pageNumber: {
                type: Number,
                required: true
            },
            activePage: {
                type: Number,
                required: true
            }
        },
        computed: {
            isActive() {
                return this.pageNumber === this.activePage
            },
            page() {
                return this.pageNumber === 0 ? "..." : this.pageNumber
            }
        },
        methods: {
            onClick() {
                if (this.pageNumber > 0) this.$emit('click', this.pageNumber)
            }
        }
    }
</script>

<style lang="scss">
    .pagination-page-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        position: relative;
        z-index: 10;
        cursor: pointer;
        color: $white;
        margin: 0 4px;
        &.dots {
            cursor: default;
        }
        &:not(.dots):after {
            content: "";
            position: absolute;
            border-radius: 50%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            transform: scale(0);
            transition: all 300ms;
            background-color: $grayscale-light;
            z-index: -1;
        }
        &:hover:not(.active):not(.dots):after {
            transform: scale(1);
        }
        &__number {
            color: inherit;
        }
        &.active {
            background-color: $yellow-middle;
            color: $grayscale-dark;
        }
    }
</style>