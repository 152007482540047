<template>
    <smiio-signals-page
            :symbols="SYMBOLS"
            :active-symbol="activeSymbol"
            :active-offset="activeOffset"
            :data="COMBINED_SIGNALS"
            :loader="GET_LOADER"
            :headers="COMBINED_HEADERS"
            :total-pages="COMBINED_SIGNALS_TOTAL_PAGES"
            :active-page="page"
            :offset-list="GET_OFFSET"
            @switch-symbol="onSwitch"
            @switch-offset="onSwitch"
            @changePage="changePage"
    />
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import SmiioSignalsPage from "../components/Pages/SmiioSignalsPage";
    export default {
        name: "SmiioSignals",
        components: {SmiioSignalsPage},
        computed: {
            ...mapGetters(['GET_LOADER', 'SYMBOLS', 'COMBINED_SIGNALS', 'COMBINED_HEADERS', 'COMBINED_SIGNALS_TOTAL_PAGES', 'GET_OFFSET']),
        },
        data: () => ({
            activeSymbol: null,
            activeOffset: '3h',
            page: 1
        }),
        methods: {
            ...mapActions([
                'getSYMBOLS',
                'getCOMBINED_SIGNALS',
                'SET_LOADER',
                "setREFRESH",
                "clearREFRESH"
            ]),
            changePage(page) {
                this.page = page
                this.refreshSignal()
            },
            onSwitch({ symbol, offset }) {
                if (symbol) this.activeSymbol = symbol.value
                if (offset) this.activeOffset = offset.value
                this.refreshSignal()
            },

            async refreshSignal() {
                this.SET_LOADER(true)
                await this.getCOMBINED_SIGNALS({symbol: this.activeSymbol, page: this.page, offset: this.activeOffset})
                await this.setREFRESH(this.refreshSignal)
                this.SET_LOADER(false)
            },
        },
        async mounted() {
            this.SET_LOADER(true)
            await this.getSYMBOLS()
            this.activeSymbol = this.SYMBOLS[0].id

            await this.refreshSignal()
        },
        destroyed() {
            this.clearREFRESH()
        },
    }
</script>
