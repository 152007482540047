var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard"},[_c('div',{staticClass:"dashboard__menu"},[_c('menu-button',{attrs:{"menu-list":_vm.symbolsList,"active":_vm.activeSymbol,"is-changeable-header":""},on:{"switch":_vm.symbolSwitch}}),_c('menu-button',{attrs:{"menu-list":_vm.offsetList.filter(el=>el.onPage.includes('smiio-signals')),"active":_vm.activeOffset,"is-changeable-header":""},on:{"switch":_vm.offsetSwitch}})],1),_c('transition',{attrs:{"name":"fade","mode":"in-out"}},[(!_vm.data.length)?_c('div',[_c('p',{staticClass:"text-center"},[_vm._v("Нет данных")])]):_vm._e(),(!_vm.loader && _vm.data)?_c('div',{class:['dashboard__table', {'has-pagination': _vm.totalPages > 1}]},[_c('table',[_c('thead',[_c('tr',_vm._l((_vm.headers),function(title){return _c('th',{key:title.title,class:[title.bgColor],domProps:{"innerHTML":_vm._s(title.title)}})}),0)]),_c('tbody',_vm._l((_vm.data),function(row){return _c('tr',{key:row.id},[_vm._l((_vm.headers),function(cell,key){return [(row[key]!==null)?_c('td',{key:key+row.id,class:[
                                      _vm.setBorder(key),
                                      {red: (key.includes('zone') && row[key].toString().toLowerCase().includes('short'))
                                          || (key.includes('day_change') && row[key].toString().toLowerCase().includes('-'))
                                          || (key.includes('signal_number') && row[key].toString().includes('-S-'))
                                          || (key.includes('rsi_motion') && row[key].toString().toLowerCase().includes('вниз'))
                                          || (key.includes('rsi_k') && row['rsi_motion'].toString().toLowerCase().includes('вниз'))
                                          || (row[key] < 0 && !key.includes('close_price'))},
                                      {green: (key.includes('zone') && row[key].toString().toLowerCase().includes('long'))
                                          || (key.includes('day_change') && !row[key].toString().toLowerCase().includes('-'))
                                          || (key.includes('signal_number') && row[key].toString().includes('-B-'))
                                          || (key.includes('rsi_motion') && row[key].toString().toLowerCase().includes('вверх'))
                                          || (key.includes('rsi_k') && row['rsi_motion'].toString().toLowerCase().includes('вверх'))
                                          || (row[key] > 0 && !key.includes('close_price'))},
                                      {bordered: cell.bgColor.includes('grey')},
                                      {bordered: cell.title.includes('f7') || cell.title.includes('4d') || cell.title.includes('12h')},
                                      {bordered: cell.title.includes('FF-')},
                                      {'text-center': ['f1', 'f2', 'f3', 'f4', 'f5', 'f6', 'f7', 'f_summ_neg', 'f_summ_pos', 'sf_12h', 'sf_1d', 'sf_2d', 'sf_3d', 'sf_3h', 'sf_4d', 'sf_4h', 'sf_6h', 'sf_8h', 'sf_summ',].includes(key)},
                                      'no-wrap'
                                      ]},[_vm._v(" "+_vm._s(!key.includes('close_price') ? row[key] : `$ ${row[key].toLocaleString()}`)+" ")]):_c('td',{key:key+row.id,class:[
                                  {'bordered-lst-child': cell.bgColor.includes('yellow')},
                                  {bordered: cell.bgColor.includes('grey')},
                                  'no-wrap'
                                  ]},[_vm._v("0 ")])]})],2)}),0)])]):_vm._e()]),(_vm.totalPages > 1)?_c('div',{staticClass:"stick-pagination"},[_c('pagination',{attrs:{"pages":_vm.totalPages,"active":_vm.activePage},on:{"changePage":function($event){return _vm.$emit('changePage', $event)}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }