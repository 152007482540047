<template>
  <div class="dashboard">
    <div class="dashboard__menu">
      <menu-button :menu-list="symbolsList" :active="activeSymbol" @switch="symbolSwitch" is-changeable-header/>
      <menu-button :menu-list="offsetList.filter(el=>el.onPage.includes('smiio-signals'))" :active="activeOffset" @switch="offsetSwitch" is-changeable-header/>
    </div>
    <transition name="fade" mode="in-out">
      <div v-if="!data.length">
        <p class="text-center">Нет данных</p>
      </div>
      <div v-if="!loader && data" :class="['dashboard__table', {'has-pagination': totalPages > 1}]">
        <table>
          <thead>
          <tr>
            <th v-for="(title) in headers" :key="title.title" v-html="title.title" :class="[title.bgColor]"/>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in data" :key="row.id">
            <template v-for="(cell, key) in headers">
              <td v-if="row[key]!==null" :key="key+row.id"
                  :class="[
                                        setBorder(key),
                                        {red: (key.includes('zone') && row[key].toString().toLowerCase().includes('short'))
                                            || (key.includes('day_change') && row[key].toString().toLowerCase().includes('-'))
                                            || (key.includes('signal_number') && row[key].toString().includes('-S-'))
                                            || (key.includes('rsi_motion') && row[key].toString().toLowerCase().includes('вниз'))
                                            || (key.includes('rsi_k') && row['rsi_motion'].toString().toLowerCase().includes('вниз'))
                                            || (row[key] < 0 && !key.includes('close_price'))},
                                        {green: (key.includes('zone') && row[key].toString().toLowerCase().includes('long'))
                                            || (key.includes('day_change') && !row[key].toString().toLowerCase().includes('-'))
                                            || (key.includes('signal_number') && row[key].toString().includes('-B-'))
                                            || (key.includes('rsi_motion') && row[key].toString().toLowerCase().includes('вверх'))
                                            || (key.includes('rsi_k') && row['rsi_motion'].toString().toLowerCase().includes('вверх'))
                                            || (row[key] > 0 && !key.includes('close_price'))},
                                        {bordered: cell.bgColor.includes('grey')},
                                        {bordered: cell.title.includes('f7') || cell.title.includes('4d') || cell.title.includes('12h')},
                                        {bordered: cell.title.includes('FF-')},
                                        {'text-center': ['f1', 'f2', 'f3', 'f4', 'f5', 'f6', 'f7', 'f_summ_neg', 'f_summ_pos', 'sf_12h', 'sf_1d', 'sf_2d', 'sf_3d', 'sf_3h', 'sf_4d', 'sf_4h', 'sf_6h', 'sf_8h', 'sf_summ',].includes(key)},
                                        'no-wrap'
                                        ]"
              >
                {{ !key.includes('close_price') ? row[key] : `$ ${row[key].toLocaleString()}` }}
              </td>
              <td :class="[
                                    {'bordered-lst-child': cell.bgColor.includes('yellow')},
                                    {bordered: cell.bgColor.includes('grey')},
                                    'no-wrap'
                                    ]" v-else :key="key+row.id">0
              </td>
            </template>
          </tr>
          </tbody>
        </table>
      </div>
    </transition>
    <div v-if="totalPages > 1" class="stick-pagination">
      <pagination :pages="totalPages" :active="activePage" @changePage="$emit('changePage', $event)"/>
    </div>
  </div>
</template>

<script>
import MenuButton from "../Blocks/MenuButton/MenuButton";
import Pagination from "../Blocks/Pagination/Pagination";
import { mapGetters } from "vuex";

export default {
  name: "CombinedSignalsPage",
  components: {Pagination, MenuButton},
  props: ['symbols', 'activeSymbol', 'activeOffset', 'data', 'loader', 'headers', 'totalPages', 'activePage', 'offsetList'],
  computed: {
    ...mapGetters(['GET_OFFSET']),
    symbolsList() {
      return this.symbols ? this.symbols.map(el => {
        return {
          name: el.symbol,
          value: el.id
        }
      }) : []
    },
  },
  methods: {
    setBorder(key) {
      switch (key) {
        case 'date' :
        case 'time' :
        case 'close_price' :
        case 'day_change' :
        case 'zone' :
        case 'f7' :
        case 'f_summ_neg' :
        case 'rsi_k' :
        case 'sf_12h' :
        case 'sf_4d' :
          return 'bold-edge'
        default : return 'bordered'
      }
    },
    symbolSwitch(symbol) {
      this.$emit('switch-symbol', { symbol: symbol })
    },
    offsetSwitch(offset) {
      this.$emit('switch-offset', { offset: offset })
    }
  }
}
</script>

<style scoped lang="scss">
.dashboard {
  margin-top: 147px;
  margin-bottom: 15px;
  overflow-y: scroll;
  height: calc(100% - 162px);

  &::-webkit-scrollbar {
    width: 0;
  }

  &__menu {
    background-color: $grayscale-dark;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    position: absolute;
    left: 0;
    right: 0;
    top: 83px;
    border-bottom: 1px solid $grayscale-middle-light;

    & .turning-spots-btn {
      flex: 1 1 auto;
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      & .spots_btn {
        min-width: 266px;
        border-left: 1px solid $grayscale-middle-light;
        padding: 8px 0 8px 15px;
        display: flex;
        align-items: center;
        column-gap: 14px;
        cursor: pointer;
        box-sizing: border-box;

        & svg {
          transition: all 300ms;
        }

        &:hover {
          svg {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  &__table {
    border: 2px solid #5B5C67;
    box-sizing: border-box;
    border-radius: 15px;
    overflow: hidden;
    //display: flex;
    //column-gap: 24px;
    width: max-content;
    &:last-child {
      margin-bottom: 27px;
    }

    & table:not(.area) {
      & tbody {
        & tr {
          &:nth-child(odd) {
            background-color: $grayscale-dark;
          }

          &:nth-child(even) {
            background-color: $grayscale-middle-light;
          }

          &:hover {
            background-color: $grayscale-light;
          }
        }
      }
    }

    & table {
      width: 100%;
      border-collapse: collapse;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0.0125em;
      text-align: left;

      &::-webkit-scrollbar {
        display: none;
      }

      & thead {
        background-color: $grayscale-middle;

        & th {
          padding: 5px 3px;
          text-align: center;
          border-bottom: 2px solid $grayscale-extra-light;
          border-right: 2px solid $grayscale-extra-light;
          min-width: 44px;

          &:last-child {
            border-right: none;
          }

          &.t-btn {
            transition: all 300ms;

            &.active {
              background: $grayscale-light;
            }

            &:hover {
              background: $grayscale-light
            }
          }
        }

        & .sub-header {
          & th {
            padding: 5px 0;
          }

          & .k {
            &-mode {
              color: $blue-middle;
              background-color: #00ACEE33;
            }
          }

          & .d {
            &-mode {
              color: $yellow-middle;
              background-color: #FFAB2D33;
            }
          }

          & .o {
            &-mode {
              color: $white;
              background-color: #E28C0B1A;

            }
          }
        }

        & .group {
          border-right: 2px solid $grayscale-extra-light;

          &:last-child {
            border-right: 0;
          }

          &.title {
            text-align: center;
            padding-left: 0;
          }

          &.yellow {
            color: $yellow-middle;
          }
        }
      }

      & tbody {
        & tr {
          transition: all 300ms;

          & th {
            text-align: center;
            border-right: 2px solid $grayscale-extra-light;
          }

          & td {
            padding: 5px 4px;
            box-sizing: border-box;
            height: 26px;
            border-bottom: 1px solid $grayscale-light;

            &.bold-edge {
              border-right: 2px solid $grayscale-extra-light;
              &:last-child {
                border-right: none;
              }
            }

            &.group {
              border-right: 2px solid $grayscale-extra-light;
            }

            &.nn {
              padding-left: 0;
              text-align: center;
            }
          }

          & td.col-devider {
            border-right: 2px solid $grayscale-extra-light;

            &:last-child {
              border-right: none;
            }
          }

          &:last-child {
            & td {
              border-bottom: none;
            }
          }
        }

        & .table-row:first-child {
          & th {
            & ~ td {
              border-top: none;
            }
          }
        }

        & .zero {
          &-row:nth-child(even) {
            background-color: $grayscale-dark;
          }

          &-row:nth-child(odd) {
            background-color: $grayscale-middle;
          }

          & th {
            border-top: 2px solid $yellow-middle;
            background-color: rgba(255, 171, 45, 0.2);
          }

        }

        & > .zero-row {
          td {
            border-top: 2px solid $yellow-middle;
          }
        }

        & > .zero-row ~ .zero-row {
          td {
            border-top: none;
          }
        }

        & .zero-row + .table-row {
          th, td {
            border-top: 2px solid $yellow-middle;
          }
        }
      }
    }
  }
}

.bordered {
  border-right: 1px solid $grayscale-light;
}

td .bordered-lst-child {
  &:last-child {
    border-right: 2px solid $grayscale-middle-light;
  }
}

.has-pagination {
  margin-bottom: 60px;
}
</style>
