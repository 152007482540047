<template>
<div class="button__menu">
    <div class="button__menu__icon-btn" @click="isMenuOpen = !isMenuOpen" v-click-outside="hide">
        <span class="subtitle-2 header">{{isChangeableHeader ? showHeader : header}}</span>
        <transition name="fade" mode="out-in">
            <svg v-if="isMenuOpen" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 9.5L7 14.5H17L12 9.5Z" fill="white"/>
            </svg>
            <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 14.5L17 9.5H7L12 14.5Z" fill="white"/>
            </svg>
        </transition>
    </div>
    <transition name="fade" mode="out-in">
        <div v-if="isMenuOpen" class="button__menu__icon-btn__menu">
            <div
                    v-for="(item, index) in menuList"
                    :key="`${item.value}-${index}-menu`"
                    :class="['button__menu__icon-btn__menu-select', 'subtitle-2', { 'active' : active === item.value }]"
                    @click="switching(item)"
                    v-html="item.name"
            />
        </div>
    </transition>
</div>
</template>

<script>
    import ClickOutside from 'vue-click-outside'

    export default {
        name: "MenuButton",
        directives: {
            ClickOutside
        },
        props: {
            active: [String, Number],
            menuList: {
                type: Array,
                required: true
            },
            header: String,
            isChangeableHeader: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            showHeader() {
                let item = this.menuList ? this.menuList.find(el => el.value === this.active) : ''
                return item ? item.name : ''
            }
        },
        data() {
            return {
                isMenuOpen: false,
            }
        },
        methods: {
            hide() {
                this.isMenuOpen = false
            },
            switching(item) {
                this.$emit('switch', item)
            }
        }
    }
</script>

<style scoped lang="scss">
    .button__menu {
        position: relative;
        &__icon-btn {
            padding: 8px 15px;
            cursor: pointer;
            display: flex;
            align-items: center;
            & .header {
                white-space: nowrap;
            }
            & svg {
                transition: all 300ms;
                margin-left: 7px;
            }
            &:hover span + svg {
                transform: scale(1.1);
            }
            &__menu {
                position: absolute;
                top: 48px;
                border: 1px solid $grayscale-extra-light;
                box-sizing: border-box;
                border-radius: 7px;
                overflow-x: hidden;
                overflow-y: auto;
                z-index: 10000;
                background-color: $grayscale-middle;
                max-height: 250px;
                &::-webkit-scrollbar {
                    width: 0;
                }
                &-select {
                    padding: 12px 16px;
                    cursor: pointer;
                    transition: all 300ms;
                    white-space: nowrap;
                    &.active {
                        background-color: $grayscale-light;
                    }
                    &:hover {
                        background-color: $grayscale-light;
                    }
                }
            }
        }
    }
</style>
