<template>
    <section class="pagination">
        <div class="pagination__left-btn" @click="prev()">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.4075 16.9974L13.7075 19.2851C13.8007 19.3783 13.8747 19.4888 13.9251 19.6105C13.9756 19.7322 14.0015 19.8627 14.0015 19.9944C14.0015 20.1261 13.9756 20.2566 13.9251 20.3783C13.8747 20.5 13.8007 20.6106 13.7075 20.7037C13.6142 20.7969 13.5035 20.8707 13.3817 20.9211C13.2599 20.9716 13.1293 20.9975 12.9975 20.9975C12.8656 20.9975 12.735 20.9716 12.6132 20.9211C12.4914 20.8707 12.3807 20.7969 12.2875 20.7037L8.28745 16.7077C8.19641 16.6126 8.12505 16.5006 8.07745 16.378C7.97744 16.1348 7.97744 15.862 8.07745 15.6187C8.12505 15.4961 8.19641 15.3841 8.28745 15.2891L12.2875 11.293C12.3804 11.1994 12.491 11.1251 12.6129 11.0743C12.7347 11.0236 12.8654 10.9975 12.9975 10.9975C13.1295 10.9975 13.2602 11.0236 13.382 11.0743C13.5039 11.1251 13.6145 11.1994 13.7075 11.293C13.8012 11.3859 13.8756 11.4964 13.9263 11.6181C13.9771 11.7399 14.0033 11.8704 14.0033 12.0023C14.0033 12.1342 13.9771 12.2648 13.9263 12.3865C13.8756 12.5082 13.8012 12.6187 13.7075 12.7116L11.4075 14.9993H22.9975C23.2627 14.9993 23.517 15.1046 23.7046 15.2919C23.8921 15.4793 23.9975 15.7334 23.9975 15.9984C23.9975 16.2633 23.8921 16.5174 23.7046 16.7048C23.517 16.8921 23.2627 16.9974 22.9975 16.9974H11.4075Z" fill="white"/>
            </svg>
        </div>
        <div class="pagination-pages">
            <div class="pagination-pages__btns">
                <template v-for="page in renderBtnsField()">
                    <pagination-btn @click="activePage=$event" :pageNumber="page" :active-page="activePage" :key="`pagination-page-${page}`"/>
                </template>
            </div>
        </div>
        <div class="pagination__right-btn" @click="next()">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.5924 16.9974L18.2924 19.2851C18.1992 19.3783 18.1252 19.4888 18.0748 19.6105C18.0243 19.7322 17.9984 19.8627 17.9984 19.9944C17.9984 20.1261 18.0243 20.2566 18.0748 20.3783C18.1252 20.5 18.1992 20.6106 18.2924 20.7037C18.3857 20.7969 18.4964 20.8707 18.6182 20.9211C18.74 20.9716 18.8706 20.9975 19.0024 20.9975C19.1343 20.9975 19.2649 20.9716 19.3867 20.9211C19.5085 20.8707 19.6192 20.7969 19.7124 20.7037L23.7124 16.7077C23.8035 16.6126 23.8748 16.5006 23.9224 16.378C24.0225 16.1348 24.0225 15.862 23.9224 15.6187C23.8748 15.4961 23.8035 15.3841 23.7124 15.2891L19.7124 11.293C19.6195 11.1994 19.5089 11.1251 19.387 11.0743C19.2652 11.0236 19.1345 10.9975 19.0024 10.9975C18.8704 10.9975 18.7397 11.0236 18.6179 11.0743C18.496 11.1251 18.3854 11.1994 18.2924 11.293C18.1987 11.3859 18.1243 11.4964 18.0735 11.6181C18.0228 11.7399 17.9966 11.8704 17.9966 12.0023C17.9966 12.1342 18.0228 12.2648 18.0735 12.3865C18.1243 12.5082 18.1987 12.6187 18.2924 12.7116L20.5924 14.9993H9.00244C8.73722 14.9993 8.48287 15.1046 8.29533 15.2919C8.1078 15.4793 8.00244 15.7334 8.00244 15.9984C8.00244 16.2633 8.1078 16.5174 8.29533 16.7048C8.48287 16.8921 8.73722 16.9974 9.00244 16.9974H20.5924Z" fill="white"/>
            </svg>
        </div>
    </section>
</template>

<script>
    import PaginationBtn from "./PaginationBtn";
    export default {
        name: "Pagination",
        components: {PaginationBtn},
        props: {
            pages: {
                type: Number,
                default: 0
            },
            active: {
                type: Number,
                default: 1
            },
        },
        data() {
            return {
                start: 1,
                maxPerList: this.pages > 10 ? 8 : 10
            }
        },
        computed: {
            activePage: {
                get() {
                    return this.active
                },
                set(val) {
                    this.$emit('changePage', val)
                }
            }
        },
        methods: {
            // changePage(page) {
            //     this.$emit('changePage', page)
            //     this.activePage = page
            // },
            prev() {
                if (this.activePage > 1) {
                    this.activePage--
                    // this.changePage(this.activePage)
                }
            },
            next() {
                if (this.activePage < this.pages) {
                    this.activePage++
                    // this.changePage(this.activePage)
                }
            },
            renderBtnsField() {
                let btnsField = []
                if (this.maxPerList === this.activePage && this.pages-this.start >= 10) {
                    this.start++; this.maxPerList++;
                }
                if (this.start > 1 && this.start === this.activePage) {
                    this.start--; this.maxPerList--;
                }
                let delta = this.start
                if (this.pages > 10 && this.pages-this.start >= 10) {
                    while (delta <= this.maxPerList && delta <= this.pages) {
                        btnsField.push(delta++);
                    }
                    btnsField.push(0); btnsField.push(this.pages);
                } else {
                    while (delta <= this.start + 10 && delta <= this.pages) {
                        btnsField.push(delta++);
                    }
                }
                return btnsField
            }
        },

    }
</script>

<style lang="scss">
.pagination {
    max-width: 576px;
    height: 44px;
    width: 100%;
    background-color: $grayscale-dark;
    box-sizing: border-box;
    border-radius: 11px;
    display: flex;
    margin: auto;
    overflow: hidden;
    position: relative;
    &__left-btn,
    &__right-btn {
        position: absolute;
        width: 80px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: $grayscale-middle;
        transition: background-color 300ms;
        z-index: 20;
        &:hover {
            background-color: $grayscale-light;
        }
    }
    &__left-btn {
        left: 0;
        top: 0;
    }
    &__right-btn {
        right: 0;
        top: 0;
    }
    &-pages {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1 1 auto;
        overflow: hidden;
        padding: 0 80px;
        &__btns {
            border-bottom: 2px solid $grayscale-middle;
            border-top: 2px solid $grayscale-middle;
            display: flex;
            flex: 1 1 auto;
            align-items: center;
            padding: 0 8px;
            justify-content: center;
            height: 100%;
            box-sizing: border-box;
        }
    }
}
</style>